<template>
  <AuthBlockComponent>
    <template v-slot:body>
      <template v-if="letterForRegistrationIsSent">
        <div class="auth__inner">
          <div class="auth__chosen-email">
            {{ email }}
          </div>
          <div class="auth__title">
            {{ localization.general.FormElements.EmailConfirm }}
          </div>
          <div class="form-block">
            <label class="form-label-text">
              {{ localization.general.FormElements.MessageAreSubmited }}
            </label>
            <input
              v-model="registrationCode"
              class="form-input"
              type="text"
            />
          </div>

        </div>
        <Btn
          :loading="verificateEmailRequestPending"
          class="auth__button"
          width="100%"
          @click="confirm()"
        >
          {{ localization.general.FormElements.Submit }}
        </Btn>
      </template>

      <template v-else>
        <AuthBlockToggler/>
        <div class="auth__inner">
          <div class="auth__inputs">
            <div class="form-block">
              <label
                class="form-label-text"
                for="email"
              >
                {{ localization.general.FormElements.EmailLabel }}
              </label>
              <input
                id="email"
                v-model="email"
                class="form-input"
                type="text"
              />
            </div>
            <div class="form-block">
              <label
                class="form-label-text"
                for="password"
              >
                {{ localization.general.FormElements.ComeUpPassword }}
              </label>

              <input
                id="password"
                v-model="password"
                class="form-input"
                type="password"
              />
              <div class="form-hint">
                {{ localization.general.FormElements.PasswordHint }} {{ minPasswordLength }}
              </div>
            </div>
            <div class="form-block">
              <label
                class="form-label-text"
                for="confirmPassword"
              >
                {{ localization.general.FormElements.PasswordConfirm }}
              </label>

              <input
                id="confirmPassword"
                v-model="passwordConfirm"
                class="form-input"
                type="password"
                @keyup.enter="signup()"
              />
            </div>
          </div>
        </div>
        <Btn
          :loading="signUpRequestPending"
          class="auth__button"
          width="100%"
          @click="signup()"
        >
          {{ localization.general.FormElements.toSignUp }}
        </Btn>
      </template>
    </template>
  </AuthBlockComponent>
</template>

<script>
import AuthBlockComponent from "@/components/Auth/AuthBlockComponent";
import AuthBlockToggler from "@/components/Auth/AuthBlockToggler";
import Btn from "@/components/UI/Btn";

export default {
  name: 'SignUp',
  components: {
    Btn,
    AuthBlockToggler,
    AuthBlockComponent
  },
  data() {
    return {
      signUpRequestPending: false,
      verificateEmailRequestPending: false,
      minPasswordLength: 4,
      email: '',
      password: '',
      passwordConfirm: '',
      registrationCode: null,
      letterForRegistrationIsSent: false
    };
  },
  methods: {
    signup() {

      let error = false;
      if (!this.email) {
        this.$toast.error(this.localization.general.FormElements.EmailPlaceholder + " " + this.localization.general.FrontErrors.FieldIsEmpty);
        error = true;
      }

      if (this.email && !this.validateEmail(this.email)) {
        this.$toast.error(this.localization.general.FrontErrors.EmailIsInvalid);
        error = true;
      }
      if (this.password.length < 4) {
        this.$toast.error(this.localization.general.FrontErrors.PasswordLength);
        error = true;
      }
      if (this.password !== this.passwordConfirm) {
        this.$toast.error(this.localization.general.FormElements.PasswordNotConfirmed);
        error = true;
      }

      if (error) return

      this.signUpRequestPending = true;

      this.$auth.signup(this.email, this.password, (isSuccess, data) => {
        if (isSuccess) {
          this.letterForRegistrationIsSent = true;
        } else {
          const code = data.data.Code;
          switch (code) {
            case 'user_already_registered':
              this.$toast.error(this.localization.general.ErrorCodes.user_already_registered);
              break;
            default:
              this.$toast.error(this.localization.general.FormElements.CheckData);
          }
        }

        this.signUpRequestPending = false;
      });

    },
    confirm() {
      if (!this.registrationCode) {
        this.$toast.error(this.localization.general.FormElements.RegistrationCodePlaceholder + " " + this.localization.general.FrontErrors.FieldIsEmpty);
      }
      this.verificateEmailRequestPending = true;
      this.$auth.confirmRegistration(this.registrationCode, this.email, (isSuccess, data) => {
          if (isSuccess) {
            this.$router.push({
              name: 'Courses'
            });
            this.$toast.success(this.localization.general.FormElements.SuccessRegister);
          } else {
            const code = data.data.Code;
            switch (code) {
              case 'registration_code_invalid':
                this.$toast.error(this.localization.general.ErrorCodes.registration_code_invalid);
                break;
              default:
                this.$toast.error(this.localization.general.FormElements.CheckData);
            }
          }
          this.verificateEmailRequestPending = false;
        }
      );
    },
  },
};
</script>
