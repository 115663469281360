class Tree {
  constructor(array, options = {prepare: null}) {
    this.array = array;
    this.options = options
  }

  buildTree(parentId) {
    const children = this.array
      .filter(item => item.parentId === parentId)
      .map(item => {

        if (this.options.prepare)
          item = this.options.prepare(item)

        return {
          ...item,
          children: this.buildTree(item.id)
        }
      });

    return children.length > 0 ? children : null;
  }
}

export default Tree;