const defImage = 'https://i.ytimg.com/vi/M6vD-iLc_PI/hqdefault.jpg'

export const listCourses = (items) => {
  return items.map((item) => ({
    ...prepareCourse(item),
    lessonsCount: item.children ? item.children.length : 0
  }))
}

export const prepareCurrentCourse = (item) => {
  return prepareCourse(item)
}

function prepareCourse(item) {
  return {
    ...item,
    previewImageUrl: defImage,
    description: '',
    progress: 0,
  }
}