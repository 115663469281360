<style lang="scss" scoped>
$medium: 1220px;
$tablet: 768px;
$tabletMini: 700px;
$phone: 500px;
.card {
    width: 100%;
    display: flex;
    height: 100%;
    position: relative;
    background: #ffffff;
    border: none;
    padding: 0;
    border-radius: 20px;
    user-select: none;
    transition: 0.2s;
    &:hover {
        filter: drop-shadow(0px 40px 50px rgba(94, 118, 159, 0.2));
    }
}
.card:hover .card__title {
    color: #12b7db;
}
.card__image {
    width: 100%;
    height: 160px;
    border-radius: 20px 20px 0px 0px;
    object-fit: fill;
    background-size: cover;
    background-repeat: no-repeat;
}
.card__title {
    font-family: 'proxima';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    text-align: center;

    /* dark */

    color: #404c61;
}
.card__title--sub {
    font-family: 'proxima';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 21px;
    text-align: center;
    height: 60px;
    display: -webkit-box;
    max-width: 300px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;

    color: #808fb0;
}
.card__stats {
    font-family: 'proxima';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    text-align: center;

    /* dark */

    color: #404c61;
}
.card__stats--sub {
    font-family: 'proxima';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 21px;
    /* identical to box height */

    text-align: center;

    /* grey */

    color: #808fb0;
}
.card__divider--horizontal {
    border: 1px solid #dde5f3;
    margin: 0 30px;
}
.card__divider--vertical {
    border: 1px solid #dde5f3;
}
.card__action {
    display: grid;
    margin-top: 22px;
    margin-bottom: 108px;
    grid-template-columns: 1fr 1px 1fr;
}
.card__wrap__button {
    display: flex;
    position: absolute;
    bottom: 10px;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.card__button {
    box-sizing: border-box;
    cursor: pointer;
    /* Auto layout */
    position: absolute;
    bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px 20px 15px 25px;
    gap: 10px;
    font-family: 'proxima';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    text-transform: uppercase;
    margin: 0 auto;
    /* dark */

    color: #404c61;
    /* dividers */

    border: 2px solid #dde5f3;
    border-radius: 100px;

    &:hover {
        font-family: 'proxima';
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
        text-transform: uppercase;
        border-color: #12b7db;
        /* blue */

        color: #12b7db;
    }
}
.card__title--padding {
    padding-top: 19px;
    padding-bottom: 10px;
}
.card__title--sub--padding {
    padding: 0 10px;
    margin-bottom: 10px;
}
@media screen and (max-width: $medium) {
}
@media screen and (max-width: $tablet) {
    .card__title {
        font-size: 24px;
    }
    .card__stats {
        font-size: 24px;
    }
    .card__title--padding {
        padding-top: 14px;
        padding-bottom: 5px;
    }
    .sub-title__padding {
        margin-bottom: 10px;
    }
}

@media screen and (max-width: $tabletMini) {
    .card__title--sub {
        font-size: 15px;
    }
}
@media screen and (max-width: $phone) {
    .card__title--padding {
        margin-bottom: 10px;
    }
}
</style>

<template>
    <div
        class="card"
        :style="{ cursor: $props.actionDisabled && 'pointer' }"
        @click="onPartnerCourse"
    >
        <div
            class="card__image"
            :style="{
                'background-image':
                    'url(' +
                    $props.item.previewImageUrl +
                    ')',
            }"
        ></div>
        <div class="card__title card__title--padding">
            {{ $props.item.name }}
        </div>
        <div
            class="card__title--sub card__title--sub--padding"
            :style="{
                marginBottom: $props.actionDisabled ? 10 + 'px' : undefined,
            }"
        >
            {{ $props.item.description }}
        </div>
        <div
            class="card__divider--horizontal"
            v-show="!$props.actionDisabled"
        ></div>
        <div class="card__action" v-show="!$props.actionDisabled">
            <div class="block">
                <p class="card__stats">
                    {{ $props.item.lessonsCount }}
                </p>
                <p class="card__stats--sub">
                    {{ localization.courses.lessonsSub }}
                </p>
            </div>
            <div class="card__divider--vertical"></div>
            <div class="block">
                <p class="card__stats">
                    {{ getProgress($props.item.progress) }}%
                </p>
                <p class="card__stats--sub">
                    {{ localization.courses.progressSub }}
                </p>
            </div>
        </div>
        <div class="card__wrap__button">
            <div
                class="card__button"
                v-show="!$props.actionDisabled"
                @click="onCourse"
            >
                {{ localization.courses.openCourse }}

                <svg
                    width="23"
                    height="22"
                    viewBox="0 0 23 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M11.5 15L15.5 11M15.5 11L11.5 7M15.5 11H7.5M21.5 11C21.5 16.5228 17.0228 21 11.5 21C5.97715 21 1.5 16.5228 1.5 11C1.5 5.47715 5.97715 1 11.5 1C17.0228 1 21.5 5.47715 21.5 11Z"
                        stroke="#12B7DB"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
import marked from 'marked'
export default {
    name: 'CoursesCard',

    props: ['item', 'actionDisabled'],
    computed: {
        lang() {
            const active = this.$store.state.ui.activeLang;

            console.log('active', active);

            if (active === 'en')
              return 'eng'
            return active
        },
    },
    methods: {
        marked(text) {
            return marked(text)
        },
        onCourse() {
            this.$router.push(`/course/${this.$props.item.id}`)
        },
        onPartnerCourse() {
            if (!this.$props.actionDisabled) return
            this.onCourse()
        },
        getProgress(progress) {
            return (progress * 100).toString().slice(0, 2)
        },
    },
    mounted() {},
}
</script>
